import * as React from 'react';
import './listDocuments.css';
import { Row, Col } from 'antd';
import "antd/dist/antd.css";
import * as api from './../../apiWrapper';
import { URL_ENTERPRISE } from './../../constant'; 
import { Scrollbars } from 'react-custom-scrollbars';
class ListDocumentsComponent extends React.Component<any, any>  {

  constructor(props: any) {
    super(props);
    this.state = {
      documents: []
    }
  }

  componentDidMount() {
    const token = localStorage.getItem('token') + '';
    api.getDocuments(token)
    .then((res: any) => {
      this.setState({
        documents: res.data.data
      });
      console.log(this.state.documents);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  datetimeHumanizer = (date: string) => {
   
    const add5hoursDate = new Date(date).getTime() + (3600000*5);
    const datetime = new Date(add5hoursDate).toLocaleString("es-ES", {timeZone: "America/Lima"});
    //const datetime = new Date(date).toISOString();
    //const _date = datetime.split('T')[0];
    //const _time = datetime.split('T')[1].split('.')[0];
    //return _date + ' ' + _time;
    return datetime;
  }

  render() {
    const h = document.documentElement.clientHeight - 260;
    var divStyle = {
       height: h,
       paddingHorizontal: '1em'
    };
    return (
      <div className="content">
        <div className="listHeader"><h2 className="subtitle2">LISTA DE DOCUMENTOS </h2><a className="button" href={`/${URL_ENTERPRISE}/certification`}>Certificar documento</a></div>
        
        <Scrollbars style={ divStyle }>
        <div className="listDocs" >
        {
          this.state.documents.map((item: any, i: any) => (
            <Row type="flex" justify="space-between" align="middle" key={ i } className="margin-bottom-mobile listItem">
               <Col xs={24} sm={18}>
               <div className="datoFile">
                <p><strong>{ item.name }</strong><br />
                  <strong>Hash:</strong> { item.document_hash }<br />
                  <strong>Fecha de registro:</strong> { this.datetimeHumanizer(item.creation_date) }</p>
                  </div>
              </Col>
              <Col xs={24} sm={6}> <a className="button _btn_secondary full" href={ "/" + URL_ENTERPRISE +"/detail-document/" + item.document_hash }>Detalle</a></Col>
            </Row>
          ))
        }
        </div>
      </Scrollbars>
        { /* <Row type="flex" justify="space-between" align="middle">
          <Col span={18}>
            <p><strong>Nombre documento registrado</strong><br />
              Hash 5454e5w4qe5qwe5qw5e4q5we45qwe45qw4e5wq4e5qwe5wq4e5qw4e5<br />
              Fecha de registro hora minuto</p>
          </Col>
          <Col span={6}> <a className="button full" href="/detail-document">Detalle</a></Col>
        </Row>

        <Row type="flex" justify="space-between" align="middle">
          <Col span={18}>
            <p><strong>Nombre documento registrado</strong><br />
              Hash 5454e5w4qe5qwe5qw5e4q5we45qwe45qw4e5wq4e5qwe5wq4e5qw4e5<br />
              Fecha de registro hora minuto</p>
          </Col>
          <Col span={6}> <a className="button full" href="/detail-document">Detalle</a></Col>
        </Row>


        <Row type="flex" justify="space-between" align="middle">
          <Col span={18}>
            <p><strong>Nombre documento registrado</strong><br />
              Hash 5454e5w4qe5qwe5qw5e4q5we45qwe45qw4e5wq4e5qwe5wq4e5qw4e5<br />
              Fecha de registro hora minuto</p>
          </Col>
          <Col span={6}> <a className="button full" href="/detail-document">Detalle</a></Col>
        </Row>

        <Row type="flex" justify="space-between" align="middle">
          <Col span={18}>
            <p><strong>Nombre documento registrado</strong><br />
              Hash 5454e5w4qe5qwe5qw5e4q5we45qwe45qw4e5wq4e5qwe5wq4e5qw4e5<br />
              Fecha de registro hora minuto</p>
          </Col>
          <Col span={6}> <a className="button full" href="/detail-document">Detalle</a></Col>
        </Row> */ }
      </div>
    )
  }
}

export default (ListDocumentsComponent);
