import * as React from 'react';
import upload from '../../assets/img/upload.svg';
import { Form, Icon, Input, Button, Checkbox } from 'antd';
import { URL_ENTERPRISE } from './../../constant'; 
import { Redirect } from 'react-router-dom';
import './verification.css';

import logoIOTA from '../../assets/img/Iota_logo.png';
import logoIDBLAB from '../../assets/img/logo_IDB-LAB.png';
import logoLACCHAIN from '../../assets/img/logo_LACCHAIN.png';
import ok from '../../assets/img/ico-ok.png';
import fail from '../../assets/img/ico-fail.png';
import qr from '../../assets/img/qr.png';
import { Row, Col } from 'antd';
import "antd/dist/antd.css";
import * as api from '../../apiWrapper';
const hash = require('hash.js')

let inputElement: HTMLInputElement | null;

export class VerificationComponent extends React.Component<any, any>  {

  constructor(props: any) {
    super(props);
    this.state = {
      step: 0,
      file1: '',
      sol: '',
      descr: '',
      hash: ''
    }
  }

  componentDidMount() {
    const hash = this.props.match.params.hash;
    if(hash !== undefined) {
      api.verifyLacChain('', hash)
          .then((res) => {
            this.setState({
                step: 1,
                file1: '',
                sol: '',
                descr: '',
                hash: hash
            });
          })
          .catch((err) => {
            this.setState({ step: 2 });
            // alert(err.message);
            console.log(err);
      });
    }
  }


  clickFile = (event: any) => {
    event.preventDefault();
    if (inputElement !== null) {
      inputElement.click();
      console.log(inputElement);
    }
  }

  onChangeFile = (data: any) => {
    // this.setState((state: any) => ({
    //   ...state,
    //   file1: data.file1
    // }));
    let fileBase64;
    let reader = new FileReader();
    reader.readAsDataURL(data.file1);
    reader.onload = () => {
      fileBase64 = reader.result ? reader.result : undefined;
      if (fileBase64 !== undefined) {
        fileBase64 = fileBase64.toString().split(',')[1];
        const _hash = hash.sha256().update(fileBase64).digest('hex');
        api.verifyLacChain('', _hash)
        .then((res) => {
          this.setState({
              step: 1,
              file1: '',
              sol: '',
              descr: '',
              hash: _hash
          });
        })
        .catch((err) => {
          this.setState({ step: 2 });
          // alert(err.message);
          console.log(err);
        });
      }
    };
    reader.onerror = (error) => {
      this.setState({ step: 0 });
      console.log('Error: ', error);
    };
  }

  onSubmitSend = (e: any) => {
    e.preventDefault();
    const { file1, sol, descr } = this.state;
    if (file1 === '') return;
    this.setState({
      step: -1
    })
    let fileBase64;
    let reader = new FileReader();
    reader.readAsDataURL(file1);
    reader.onload = () => {
      fileBase64 = reader.result ? reader.result : undefined;
      if (fileBase64 !== undefined) {
        fileBase64 = fileBase64.toString().split(',')[1];
        const _hash = hash.sha256().update(fileBase64).digest('hex');
        api.verifyLacChain('', _hash)
        .then((res) => {
          this.setState({
              step: 1,
              file1: '',
              sol: '',
              descr: '',
              hash: _hash
          });
        })
        .catch((err) => {
          this.setState({ step: 2 });
          // alert(err.message);
          console.log(err);
        });
      }
    };
    reader.onerror = (error) => {
      this.setState({ step: 0 });
      console.log('Error: ', error);
    };
  }

  selectStep() {
    const { sol, descr } = this.state.sol
    if (this.state.step === -1) {
      return (
        <div className="loader">
          <h1>LOADING</h1>
        </div>
      );
    }
    if (this.state.step === 0) {
      return (
        <div>
          <h2 className="subtitle2">VERIFICAR UN DOCUMENTO DIGITAL</h2>
          <div className="panel">
            <button className="upload-btn" onClick={this.clickFile}><img className="upload" src={upload} /></button>
            <p className="subtitle">Clic en el ícono para seleccionar un documento</p>
            <br />
            <p className="upload-txt">{this.state.file1.name}</p>
            <br />
            <input type="file" ref={input => inputElement = input} className="hidden" onChange={(e) => this.onChangeFile({ file1: e.target.files ? e.target.files[0] : '' })} />



            <div className="text-center">
              {/*<button className="button" onClick={() => this.setState({ step: 1 })} disabled={!this.state.file1} >Registrar</button> */}
              {/*<button className="button" onClick={() => this.setState({ step: 1 })} >Ver Mensaje OK</button>
                <button className="button" onClick={() => this.setState({ step: 2 })} >Ver Mensaje INVALIDO</button>*/}
              {/* <button className="button" onClick={(e) => this.onSubmitSend(e)} disabled={!this.state.sol || !this.state.descr} >Registrar</button> */}
            </div>

          </div>

          <Row type="flex" justify="center" className="margin-bottom">
            <a className="button" href={`/${URL_ENTERPRISE}`}>Menú principal</a>
          </Row>

        </div>
      );
    }
    if (this.state.step === 1) {
      //RESPUESTA POSITIVA DOCUMENTO VALIDADO
      return <Redirect to={ `/${URL_ENTERPRISE}/verificationOK/${this.state.hash}`} />;
      {/*
        return (
        <div>
          <h2 className="subtitle2">Validar documento digital</h2>
          <div className="panel2">
            <Row type="flex" justify="center" align="middle" className="margin-bottom">

              <p className="rpta"><img src={ok} width="98" /><br /><strong>Documento validado OK</strong></p>

            </Row>

            <p><strong>Detalles del documento original certificado:</strong></p>

            <Row type="flex" justify="space-between" align="middle" className="margin-bottom">
              <Col span={6}>
                <div className="logo">
                  <img src={qr} width="140" />
                </div>
              </Col>
              <Col span={18}>
                <p><strong>Magic Chain Trust ID</strong>: d54444444445454545454545454</p>
                <p><strong>Nombre del documento digital certificado</strong>: autoriza-sss-dd-fsdfsdfdsf.pdf</p>
                <p><strong>Hash (SHA256) del documento digital original</strong>: 78454345535544355453445535445354534534254fda5d54d5qw45qw4e5rqwe5</p>
              </Col>
            </Row>

            <p><strong>Registro de evidencias en los siguientes Blockchains/DLTs:</strong></p>

            <Row type="flex" justify="space-between" align="middle">
              <Col span={6}>
                <div className="logo">
                  <img src={logoIOTA} width="140" />
                </div>
              </Col>
              <Col span={18}>
                <p><strong>TX IOTA</strong>:<br />
                  GJDKFJSKDFSDFSDF454SD5F4S5D4F5SD4F5DS4F5DS4F54DS5F4DS5F4DSASDASD5ASDASD5D5AS4D5SAD<br />
                  Tipo: IOTA Tangle (DAG) - Público<br />
                  <a href="#" target="_blank">Link al explorador</a></p>
              </Col>
            </Row>


            <Row type="flex" justify="space-between" align="middle" className="margin-bottom">
              <Col span={6}>
                <div className="logo">
                  <img src={logoLACCHAIN} width="140" />
                </div>
              </Col>
              <Col span={18}>
                <p><strong>TX LACCHAIN</strong>:<br />
                  GJDKFJSKDFSDFSDF454SD5F4S5D4F5SD4F5DS4F5DS4F54DS5F4DS5F4DSASDASD5ASDASD5D5AS4D5SAD<br />
                  Tipo: Quorum (Fork Ehereum) en TestNet - Privado<br />
                  <a href="#" target="_blank">Link al explorador</a></p>
              </Col>
            </Row>

            <Row type="flex" justify="center" align="middle">
              <a className="button" onClick={() => this.setState({ step: 0 })} >Validar otro documento</a>
            </Row>
          </div>
        </div>
 
      );
             */}
    }
    if (this.state.step === 2) {
      //ERROR
      return <Redirect to={`/${URL_ENTERPRISE}/verificationFailed`} />;
      {/*
      return (
        <div>

          <div className="panel2">

            <h2 className="subtitle2">Validar documento digital</h2>

            <Row type="flex" justify="center" align="middle" className="margin-bottom">

              <p className="rpta"><img src={fail} width="98" /></p>

            </Row>

            <p className="text-center margin-bottom">Este documento NO HA SIDO validado en la Blockchain con Magic Chain TRUST<br /> o es un documento adulterado.</p>

            <Row type="flex" justify="center" align="middle">
              <a className="button" onClick={() => this.setState({ step: 0 })} >Validar otro documento</a>
            </Row>

          </div>
        </div>
      );
      */}
    }
  }
  render() {
    return (
      <div className="centerWrap">
        {this.selectStep()}
      </div>
    )
  }
}



export default (VerificationComponent);