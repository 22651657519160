import React, { Component } from 'react';
//import logo from '../../assets/img/logo-MagicChain.svg';
import logo from '../../assets/img/logo_TRUST.png';
import { URL_ENTERPRISE, ITEM_ENTERPRISE, URL_BASE_IMAGE } from './../../constant'; 
import './header.min.css';

export class HeaderComponent extends Component {

  onLogout = () => {
    localStorage.clear();
    window.location.reload();
    window.location.href =  `/${URL_ENTERPRISE}`;
  }

  render() {

    return (
      <header className="header-component" >
        <div className="_boxed">

          <div className="header">
            <div>
              <a href={`/${URL_ENTERPRISE}`} className="logo"><img src={logo} alt="Ir al home" width="170" /></a>
            </div>
            <div>
              <a className="logo logo_enterprise"><img src={URL_BASE_IMAGE + ITEM_ENTERPRISE.logo_path} alt="Logo enterprise" width="170"  /></a>
            </div>
            <div className="menu">
              {
                localStorage.getItem("token") && 
                <>
                  <a href="#" onClick={this.onLogout} className="button _btn_primary">Logout</a><a href={`/${URL_ENTERPRISE}/documents`} className="button _btn_terciary">Dashboard</a>
                </>
                ||
                <>
                  <a href={`/${URL_ENTERPRISE}/login`} className="button _btn_primary">Login</a> {/* <a href={`/${URL_ENTERPRISE}/register`} style={{backgroundColor:'#2c2a29'}} className="button">Registro</a> */}
                </>
              }
            </div>

          </div>
        </div>
      </header>
    )
  }
}
