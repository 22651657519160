import * as React from "react";
import upload from "../../assets/img/upload.svg";
import { Form, Icon, Input, Button, Checkbox } from "antd";
import { URL_ENTERPRISE } from './../../constant';
import { Redirect } from "react-router-dom";
import "./verificationOK.css";

import logoIOTA from "../../assets/img/Iota_logo.png";
import logoIDBLAB from "../../assets/img/logo_IDB-LAB.png";
import logoLACCHAIN from "../../assets/img/logo_LACCHAIN.png";
import ok from "../../assets/img/ico-ok.png";
import fail from "../../assets/img/ico-fail.png";
import qr from "../../assets/img/qr.png";
import checkok from "../../assets/img/check-ok.svg";
import { Row, Col } from "antd";
import * as api from "../../apiWrapper";
import "antd/dist/antd.css";

import QRCode from "qrcode";

import axios from "axios";

let inputElement: HTMLInputElement | null;

export class VerificationOKComponent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      step: 0,
      file1: "",
      sol: "",
      descr: "",
      uuid: "",
      name: "",
      hash: "",
      entidad: ""
    };
  }

  componentDidMount() {
    const hash = this.props.match.params.hash;
    const token = localStorage.getItem("token") + "";
    api
      .documentDetail(token, hash)
      .then((res: any) => {
        const data = res.data.document;
        this.setState({
          uuid: data.uuid,
          name: data.name,
          hash: data.document_hash,
          tx_hash: data.transaction_hash,
          url_explorer: data.url_explorer,
          entidad: data.certifying_entity
        });
        const qrcode_doc = this.refs.qrcode_doc;
        const url_doc =
          "https://magictrust.magia.digital/verification/" + data.document_hash; // el id deber ser del documento !!!
        QRCode.toCanvas(qrcode_doc, url_doc);
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { uuid, name, hash, tx_hash, url_explorer, entidad } = this.state;
    return (
      <div className="content detalleDocumento">
        <Row type="flex" justify="center" align="middle">
          <p className="rpta">
            <img src={checkok} width="60" />
          </p>
        </Row>

        <h2 className="subtitle2">Validar documento digital</h2>

        <p className="subtitle3">
          Entidad Certificadora: <strong>{entidad}</strong>
        </p>

        <div className="panel2">
          <p>
            <strong>Detalles del documento original certificado:</strong>
          </p>

          <Row
            type="flex"
            justify="space-between"
            align="middle"
          >
            <Col xs={24} sm={8}>
              <div className="logo">
                <canvas ref="qrcode_doc"></canvas>
              </div>
            </Col>
            <Col xs={24} sm={16}>
              <p>
                <strong>Magic Chain Trust ID</strong>: {uuid}
              </p>
              <p>
                <strong>Nombre del documento digital certificado</strong>: {name}
              </p>
              <p>
                <strong>Hash (SHA256) del documento</strong>: {hash}
              </p>
            </Col>
          </Row>

          <p>
            <strong>
              Registro de evidencias en los siguientes Blockchains/DLTs:
            </strong>
          </p>

          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="margin-bottom"
          >
            <Col xs={24} sm={8}>
              <div className="logo">
                <img src={logoLACCHAIN} width="140" />
              </div>
            </Col>
            <Col xs={24} sm={16}>
            <div className="datoFile">
              <p>
                <strong>Transfer Hash</strong>: {tx_hash}
                </p>
                <p>
                <strong>Tipo</strong>: Quorum (Fork Ehereum) en TestNet - Privado
                </p>
                <p>
                <a href={url_explorer} target="_blank">
                  Link al explorador
                </a>
              </p>
              </div>
            </Col>
          </Row>

          <Row type="flex" justify="center" align="middle">
            <a className="button" href={`/${URL_ENTERPRISE}/verification`}>
              Validar otro documento
            </a>
          </Row>
        </div>
      </div>
    );
  }
}

export default VerificationOKComponent;
