import React, { Component } from 'react';
import logo from '../../assets/img/logo-MagicChain.svg';
import logoIOTA from '../../assets/img/Iota_logo.png';
import logoLACCHAIN from '../../assets/img/logo_LACCHAIN.png';
import './footer.css';

export class FooterComponent extends Component {
  render() {
    return (
      <footer className="footer-component" >
        <div className="_boxed" style={{display:'flex'}}>
          <div className="wrapper" style={{placeContent:'center'}}>
            <div> <img src={logoLACCHAIN} /> </div>
          </div>
          <div className="wrapper" style={{placeContent:'baseline'}}>
            <div ><img src={logoIOTA} /> </div>
          </div>
          <div className="wrapper" style={{textAlign: 'end'}}>
            <div> <p>Powered by <a href="https://magia.digital/principal/categoria/magic-chain" target="_blank"><img src={logo} /></a></p> </div>
          </div>
        </div>
      </footer>
    )
  }
}