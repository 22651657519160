import React, { Component } from "react";
import { Form, Input, Icon, Col, Row } from "antd";
import * as api from "./../../apiWrapper";
import "./login.css";
import LoginHome from "./loginHome";
import LoginAuthenticationGoogle from "./loginAuthenticationGoogle";
import LoginEnabledGoogle from "./loginEnabledGoogle";
import { URL_ENTERPRISE } from './../../constant';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class LoginComponent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      paso: 1,
      user: {
        tokenFirst: "",
        email: "",
        password: "",
        confirpassword: "",
        google_key: "",
        otp_key: "",
        text_qr: ""
      },
      textBoton: "LOGIN",
      buttonEnabled: true
    };
  }

  handleChange = (value: any, key: any) => {

    this.setState((state: any) => ({
      ...state,
      [key]: {
        ...state[key],
        ...value
      }
    }));
  };

  onLogin = (e: any, _props: any) => {
    e.preventDefault();
    _props.form.validateFields((err: any) => {
      if (!err) {
        const { user } = this.state;
        this.setState({
          textBoton: "PROCESANDO...",
          buttonEnabled: false
        });
        api
          .login(user.email, user.password, URL_ENTERPRISE)
          .then((res: any) => {
            console.log(res);
            this.setState((state: any) => ({
              ...state,
              user: {
                ...state.user,
                tokenFirst: res.data.token
              }
            }));
            localStorage.setItem("email", user.email);
            localStorage.setItem("sec_lev", res.data.security_level);
            localStorage.setItem("platform", res.data.platform);
            if (localStorage.getItem("sec_lev") === "F") {
              const { user } = this.state;
              api
                .generateOtpKey(user.tokenFirst)
                .then((_res: any) => {
                  const otp_key = _res.data["otp_key"];
                  const text_qr =
                    "otpauth://totp/MAGICTRUST:" +
                    user.email +
                    "?secret=" +
                    otp_key +
                    "&issuer=MAGICTRUST";
                  this.setState((state: any) => ({
                    ...state,
                    user: {
                      ...state.user,
                      otp_key,
                      text_qr
                    },
                    paso: 2,
                    textBoton: "HABILITAR",
                    buttonEnabled: true
                  }));
                })
                .catch(err => {
                  //console.log(err);
                  //console.log(err.data.error);

                  const mensaje = err.data.error
                    ? JSON.stringify(err.data.error).replace(/\"/g, "")
                    : JSON.stringify(err).replace(/\"/g, "");
                  toast.error("" + mensaje, {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                  });
                });
            } else {
              this.setState({
                paso: 3,
                textBoton: "INGRESAR",
                buttonEnabled: true
              });
            }
          })
          .catch(err => {
            //console.log(err);
            const mensaje = err.response.data.error
              ? JSON.stringify(err.response.data.error).replace(/\"/g, "")
              : JSON.stringify(err).replace(/\"/g, "");
            toast.error("" + mensaje, {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });

            this.setState({
              textBoton: "LOGIN",
              buttonEnabled: true
            });
          });
      }
    });
  };

  onGAFirst = (e: any, _props: any) => {
    e.preventDefault();
    _props.form.validateFields((err: any) => {
      if (!err) {
        const { user } = this.state;
        //if (user.password === user.confirpassword) {
          this.setState({
            textBoton: "HABILITANDO...",
            buttonEnabled: false
          });
          const time = (Date.now() / 1000).toFixed(0);
          api
            .enabledTwoFactor(
              user.tokenFirst,
              user.password,
              user.otp_key,
              user.google_key,
              time
            )
            .then((res: any) => {
              console.log(res);
              //console.log(res.data.error);
              if (!res.data.error) {
                console.log(res);
                this.setState((state: any) => ({
                  user: {
                    ...state.user
                  },
                  paso: 3,
                  textBoton: "INGRESAR",
                  buttonEnabled: true
                }));
              } else {
                const mensaje = res.data.error
                  ? JSON.stringify(res.data.error).replace(/\"/g, "")
                  : JSON.stringify(err).replace(/\"/g, "");
                toast.error("" + mensaje, {
                  position: toast.POSITION.TOP_CENTER,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true
                });
                this.setState({
                  textBoton: "HABILITAR",
                  buttonEnabled: true
                });
              }
            })
            .catch(err => {
              console.log(err);
              const mensaje = err.response.data.error
                ? JSON.stringify(err.response.data.error).replace(/\"/g, "")
                : JSON.stringify(err).replace(/\"/g, "");
              toast.error("" + mensaje, {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              });
              this.setState({
                textBoton: "HABILITAR",
                buttonEnabled: true
              });
            });
        /*} else {
          toast.error("Tu contraseña es invalida", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        }*/
      }
    });
  };

  twoFactor = (e: any, _props: any) => {
    e.preventDefault();
    _props.form.validateFields((err: any) => {
      if (!err) {
        const { user } = this.state;
        this.setState({
          textBoton: "VERIFICANDO...",
          buttonEnabled: false
        });
        const time = (Date.now() / 1000).toFixed(0);
        api
          .verifyTwoFactor(user.tokenFirst, user.otp_key, time)
          .then((res: any) => {
            localStorage.setItem("auth", res.data.auth);
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("keep_doc", res.data.keep_document);
            /*toast.success("Bienvenido a MAGIC TRUST", {
              position: toast.POSITION.TOP_CENTER
            });*/
            console.log(res);
            /*setTimeout(function() {
              window.location.href = "/certification";
            }, 1000);*/
            window.location.href = `/${URL_ENTERPRISE}/certification`;
          })
          .catch(err => {
            console.log(err);

            const mensaje = err.response.data.error
              ? JSON.stringify(err.response.data.error).replace(/\"/g, "")
              : JSON.stringify(err).replace(/\"/g, "");
            toast.error("" + mensaje, {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });

            this.setState({
              textBoton: "INGRESAR",
              buttonEnabled: true
            });
          });
      }
    });
  };

  componentDidMount() {}

  renderSteps = (step: any, user: any, textBoton: any, buttonEnabled: any) => {
    if (step === 1) {
      return (
        <LoginHome
          user={user}
          textBoton={textBoton}
          buttonEnabled={buttonEnabled}
          handleChange={this.handleChange}
          onLogin={this.onLogin}
        />
      );
    } else if (step === 2) {
      return (
        <LoginEnabledGoogle
          user={user}
          textBoton={textBoton}
          buttonEnabled={buttonEnabled}
          handleChange={this.handleChange}
          onGAFirst={this.onGAFirst}
        />
      );
    } else if (step === 3) {
      return (
        <LoginAuthenticationGoogle
          user={user}
          textBoton={textBoton}
          buttonEnabled={buttonEnabled}
          handleChange={this.handleChange}
          twoFactor={this.twoFactor}
        />
      );
    }
  };

  render() {
    const { paso, user, textBoton, buttonEnabled } = this.state;
    //    const { t } = this.props;
    return (
      <div className="loginWrap">
        {this.renderSteps(paso, user, textBoton, buttonEnabled)}
      </div>
    );
  }
}

export default LoginComponent;
