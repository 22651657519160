import * as React from "react";
import { Form, Input } from "antd";
import * as api from "./../../apiWrapper";
import "./register.css";
import { toast } from "react-toastify";
import { URL_ENTERPRISE } from './../../constant'; 
import "react-toastify/dist/ReactToastify.css";

export class RegisterComponent extends React.Component<any, any> {
  textBoton = "REGISTRAR";

  constructor(props: any) {
    super(props);
    this.state = {
      user: {
        certifying_entity: "",
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: ""
      },
      buttonEnabled: true
    };
  }

  componentDidMount() {
    //this.props.form.validateFields();
  }

  handleConfirmBlur = (e: any) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule: any, value: any, callback: any) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("¡Dos contraseñas que ingresas son inconsistentes!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule: any, value: any, callback: any) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirmPassword"], { force: true });
    }
    callback();
  };

  handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const { user } = this.state;
        this.textBoton = "PROCESANDO...";
        this.setState({
          buttonEnabled: false
        });
        api
          .register(
            user.certifying_entity,
            user.firstName,
            user.lastName,
            user.email,
            user.password
          )
          .then((res: any) => {
            toast.success("Te has registrado correctamente a MAGIC TRUST", {
              position: toast.POSITION.TOP_CENTER
            });
            setTimeout(function() {
              window.location.href = `/${URL_ENTERPRISE}/login`;
            }, 1000);
          })
          .catch(err => {
            const mensaje = err.response.data.error
              ? JSON.stringify(err.response.data.error).replace(/\"/g, "")
              : JSON.stringify(err).replace(/\"/g, "");
            toast.error("" + mensaje, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
            this.textBoton = "REGISTRAR";
            this.setState({
              buttonEnabled: true
            });
          });
      }
    });
  };

  onChange = (value: any) => {
    this.setState((state: any) => ({
      ...state,
      user: {
        ...state.user,
        ...value
      }
    }));
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      }
    };
    const { user } = this.state;
    return (
      <div className="content">
        <h2 className="title">REGISTRO</h2>
        <div className="form">
          <Form {...formItemLayout}>
            <Form.Item label="Entidad Certificadora">
              {getFieldDecorator("certifying_entity", {
                rules: [
                  {
                    required: true,
                    message: "Por favor ingresa tu empresa"
                  }
                ]
              })(
                <Input
                  onChange={(e: any) => {
                    this.onChange({ certifying_entity: e.target.value });
                  }}
                />
              )}
            </Form.Item>

            <Form.Item label="E-mail">
              {getFieldDecorator("email", {
                rules: [
                  {
                    type: "email",
                    message: "El campo no es un E-mail válido!"
                  },
                  {
                    required: true,
                    message: "Por favor ingresa tu E-mail!"
                  }
                ]
              })(
                <Input
                  onChange={(e: any) => {
                    this.onChange({ email: e.target.value });
                  }}
                />
              )}
            </Form.Item>

            <Form.Item label="Nombres">
              {getFieldDecorator("firstName", {
                rules: [
                  {
                    required: true,
                    message: "Por favor ingresa tu nombre"
                  }
                ]
              })(
                <Input
                  onChange={(e: any) => {
                    this.onChange({ firstName: e.target.value });
                  }}
                />
              )}
            </Form.Item>

            <Form.Item label="Apellidos">
              {getFieldDecorator("lastName", {
                rules: [
                  {
                    required: true,
                    message: "Por favor ingresa tus apellidos"
                  }
                ]
              })(
                <Input
                  onChange={(e: any) => {
                    this.onChange({ lastName: e.target.value });
                  }}
                />
              )}
            </Form.Item>

            <Form.Item label="Password" hasFeedback>
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "Por favor ingresa tu password!"
                  }
                ]
              })(
                <Input.Password
                  onChange={(e: any) => {
                    this.onChange({ password: e.target.value });
                  }}
                />
              )}
            </Form.Item>

            <Form.Item label="Confirmar password" hasFeedback>
              {getFieldDecorator("confirmPassword", {
                rules: [
                  {
                    required: true,
                    message: "Por favor confirma tu password!"
                  },
                  {
                    validator: this.compareToFirstPassword
                  }
                ]
              })(
                <Input.Password
                  onBlur={this.handleConfirmBlur}
                  onChange={(e: any) => {
                    this.onChange({ confirmPassword: e.target.value });
                  }}
                />
              )}
            </Form.Item>

            <div className="form-row align-center">
              <button
                disabled={!this.state.buttonEnabled}
                className="form-button"
                onClick={this.handleSubmit}
              >
                {this.textBoton}
              </button>
            </div>
            <p className="align-center">
              Si ya tienes cuenta, ingresa&nbsp;<a href={`/${URL_ENTERPRISE}/login`}>aquí</a>.
            </p>
          </Form>
        </div>
      </div>
    );
  }
}

export default Form.create({ name: "horizontal_register" })(RegisterComponent);
