import * as React from "react";
import { Form, Input, Col } from "antd";
import ReactInputVerificationCode from 'react-input-verification-code';

class LoginAuthenticationGoogle extends React.Component<any, any> {
  public constructor(props: any) {
    super(props);
    this.state = {
      isError: false,
      dontInit: false
    }
  }

  addSpaceAfter3Digits = (data:any, type:string) => {
    this.setState({dontInit: true});

    if(data.otp_key[0] === "·" && this.state.dontInit) 
      this.setState({isError: true})
    else  
      this.setState({isError: false})

    this.props.handleChange(data, type);
  }

  preValidation = (e:any) => {
    let value = this.props.user.otp_key[0];
    let value2 = this.props.user.otp_key[5];
    if(value === "·" || value2 === "·") {
      this.setState({isError: true})
      return;
    }

    this.props.twoFactor(e, this.props)
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { twoFactor, handleChange, textBoton, buttonEnabled, user } = this.props;
    const { isError } = this.state;
    return (
      <div className="login">
        <h2 className="title">TWO-FACTOR</h2>
        <p className="text text-center">
          Please enter the Google Authenticator code in the following field.
        </p>
        <Form>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Col xs={24} sm={8} className="text-center">
              <img src={require("../../assets/img/ico-gauthe.png")} alt="" />
            </Col>
            <Col xs={24} sm={12}>


              <label className="ant-form-item-required" style={{ color: 'white' }} title="E-mail">GOOGLE AUTHENTICATOR</label>
              <ReactInputVerificationCode 
                length={6}
                //placeholder={""}
                onChange={(value) => this.addSpaceAfter3Digits({ otp_key: value }, "user")}
              />
              {<p style={{marginTop: '-13px'}} className={isError ? 'isError__Messagecustom' : 'Message__Custom'}>Por favor ingresa tu token</p>}


            </Col>
          </Form.Item>
          <div className="align-center">
            <button
              disabled={!buttonEnabled}
              className="button"
              onClick={(e: any) => this.preValidation(e)}
            >
              {textBoton}
            </button>
          </div>
        </Form>
      </div>
    );
  }
}

export default Form.create<any>({ name: "vertical_login" })(
  LoginAuthenticationGoogle
);
