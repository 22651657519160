import axios from 'axios';

const url = 'https://magictrust.magia.digital/api/';
const pathLogin = 'auth/login/';
const pathGenerateOtpKey = 'auth/generate-otp-key';
const pathEnaledTwoFactor = 'auth/enable-two-factor';
const pathVerifyTwoFactor = 'auth/verify-two-factor';
const pathRegister = 'auth/register/';
const pathGetDocuments = 'document/list-by-client/';
const pathDocumentDetail = 'document/get/';
const pathClientList = 'client/list/';
//const pathUploadLChain = 'lacchain/upload-document/';
const pathUploadLChain = 'service/upload-document/';
const pathUploadIota = 'iota/upload-document/';
//const pathVerifyLChain = 'lacchain/check-validity/';
const pathVerifyLChain = 'service/check-validity/';
const pathUploadBacth = 'service/upload-document-batch/';

const login = (email: any, password: any, clientId: any) => {
    return new Promise((resolve, reject) => {
        const body = {
            email: email,
            password: password,
            client_id: clientId
        }
        axios.post(url + pathLogin, body)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

const register = (certifying_entity: any, firstName: any, lastName: any, email: any, password: any) => {
    return new Promise((resolve, reject) => {
        const body = {
            certifying_entity: certifying_entity,
            first_name: firstName,
            last_name: lastName,
            password: password,
            email: email,
        }
        axios.post(url + pathRegister, body)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

const getDocuments = (token: string) => {
    const header = {
        headers: {
            authorization: 'Bearer ' + token
        }
    };
    return new Promise((resolve, reject) => {
        axios.post(url + pathGetDocuments, null, header)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

const documentDetail = (token: string, hash: string) => {
    const header = {
        headers: {
            authorization: 'Bearer ' + token
        },
        /*params: {
            document_hash: hash
        }*/
    };
    return new Promise((resolve, reject) => {
        axios.get(url + pathDocumentDetail + hash, header)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

const clientList = (token: string) => {
    const header = {
        headers: {
            authorization: 'Bearer ' + token
        }
    };
    
    return new Promise((resolve, reject) => {
        axios.post(url + pathClientList, null, header)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

const uploadLacChain = (token: string, name: string, data: any) => {
    // const uploadLacChain = (token: string, name: string, content?: string, hash?: string) => {
    const header = {
        headers: {
            authorization: 'Bearer ' + token
        }
    };
    const nanaa = Object.keys(data.data)[0];
    const body = {
        name: name,
        // content: content,
        [nanaa]: data.data[nanaa]
    };
    return new Promise((resolve, reject) => {
        axios.post(url + pathUploadLChain, body, header)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export interface IUploadbatch {
    docs: documentItem[];
}
export interface documentItem {
    name: string;
    content?: string;
    document_hash?: string;
}

const uploadBacth = (token: string, ibatch: IUploadbatch) => {    
    const header = {
        headers: {
            authorization: 'Bearer ' + token
        }
    };
 /*
    const body = {
        "docs":[
            ibatch
        ]
    };
    */
/*
    const body = {
        ibatch
    }; 
    */
 
    return new Promise((resolve, reject) => {
        axios.post<IUploadbatch>(url + pathUploadBacth, ibatch, header)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

const uploadIota = (token: string, name: string, content?: string, hash?: string) => {
    const header = {
        headers: {
            authorization: 'Bearer ' + token
        }
    };
    const body = {
        name: name,
        content: content,
        document_hash: hash
    };
    return new Promise((resolve, reject) => {
        axios.post(url + pathUploadIota, body, header)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

const verifyLacChain = (content?: string, hash?: string) => {
    const body = {
        name: name,
        content: content,
        document_hash: hash
    };
    return new Promise((resolve, reject) => {
        axios.post(url + pathVerifyLChain, body)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}


const generateOtpKey = (token: string) => {
    const header = {
        headers: {
            authorization: 'Bearer ' + token
        },
    };
    return new Promise((resolve, reject) => {
        axios.post(url + pathGenerateOtpKey, null, header)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}


const enabledTwoFactor = (token: string, password: string, otp_key: string, otp_token: string, time: string) => {
    const header = {
        headers: {
            authorization: 'Bearer ' + token
        },
    };
    const body = {
        password: password,
        otp_key: otp_key,
        otp_token: otp_token,
        time: time
    };
    return new Promise((resolve, reject) => {
        axios.post(url + pathEnaledTwoFactor, body, header)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}


const verifyTwoFactor = (token: string, otp_token: string, time: string) => {
    const header = {
        headers: {
            authorization: 'Bearer ' + token
        },
    };
    const body = {
        otp_token: otp_token,
        time: time
    };
    return new Promise((resolve, reject) => {
        axios.post(url + pathVerifyTwoFactor, body, header)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}



export { login, register, getDocuments, documentDetail, uploadLacChain, uploadIota, verifyLacChain, generateOtpKey, enabledTwoFactor, verifyTwoFactor, uploadBacth, clientList };
