import * as React from 'react';
import './App.css';
import { HeaderComponent } from './components/header/header';
import { MainComponent } from './components/main/main';
import { FooterComponent } from './components/footer/footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ITEM_ENTERPRISE } from './constant';

toast.configure()

class App extends React.Component<any, any> {
  render() {

    if(ITEM_ENTERPRISE !== null) {
      console.log(ITEM_ENTERPRISE);
      let theme = (JSON.parse(ITEM_ENTERPRISE.theme_config || "{}")) || null;
      if(theme !== null && typeof window !== "undefined"){
        var componentHTML:any = document.querySelector("html");
        if(theme.color !== null && theme.color !== undefined){
          componentHTML.style.setProperty('--primary-color', theme.color);
        }
      } 
    }

    return (
      <div className="App">
        <HeaderComponent/>
        <MainComponent/>
        <FooterComponent/>
      </div>
    );
  }
}

export default App;
