import * as React from "react";
import "./certification.css";
import logoIOTA from "../../assets/img/Iota_logo.png";
import logoLACCHAIN from "../../assets/img/logo_LACCHAIN.png";
import * as api from "../../apiWrapper";
import * as config from "../../config";
import * as utils from "../../utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { URL_ENTERPRISE } from './../../constant';
import { Row, Col, Icon, Spin, Upload, Tooltip } from "antd";
import "antd/dist/antd.css";

const Dragger = Upload.Dragger;
const hash = require("hash.js");
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
export class CertificationComponent extends React.Component<any, any> {
  textBoton = "Certificar";

  constructor(props: any) {
    super(props);
    this.state = {
      step: 0,
      file1: "",
      sol: "",
      descr: "",
      hash: "",
      buttonEnabled: true,
      base64: "",
      fileList: [],
      uploading: false,
      filesBatch: [],
      filesBatchResult: []
    };
  }

  onCertificar = (e: any) => {
    e.preventDefault();
    const { filesBatch } = this.state;
    console.log(filesBatch);
    //VALIDANDO SI HAY ALMENOS UN ARCHIVO

    if (filesBatch.length> 50) {

      toast.error("SOLAMENTE PUEDE SUBIR HASTA 50 DOCUMENTOS", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });

    }else{


      if (filesBatch.length) {
        this.textBoton = "Procesando...";
        this.setState({
          buttonEnabled: false,
          step: -1
        });
  
        const token = localStorage.getItem("token") + "";
        const documentsBatch = {
          docs: filesBatch
        };
  
        api
          .uploadBacth(token, { ...documentsBatch })
          .then((res: any) => {
            console.log(res.data);
            this.setState({
              step: 1,
              file1: "",
              sol: "",
              descr: "",
              base64: "",
              //filesBatchResult: [res.data]
              filesBatchResult: res.data.result
            });
          })
          .catch(err => {
            this.setState({ step: 0 });
            console.log(err);
            const mensaje = err ? err : err.response;
            toast.error("" + mensaje, {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
          });
      } else {
        toast.error("SELECCIONE UN DOCUMENTO", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }

    }


  };

  handleRemoveFile = (e: any, fileId: any) => {
    e.preventDefault();
    //DESHABILITAMOS OPEN DIALOG CLICK DEL INPUT FILE DEL COMPONENTE UPLOAD DE ANT DESIGN.
    var miInputFileAD = document.querySelector(
      ".dragu input[type=file]"
    ) as HTMLInputElement;
    miInputFileAD.disabled = true;
    setTimeout(function() {
      miInputFileAD.disabled = false;
    }, 1);
    //----//
    const { fileList } = this.state;
    this.setState({
      fileList: fileList.filter((item: any) => item.uid !== fileId),
      filesBatch: fileList.filter((item: any) => item.uid !== fileId)
    });
  };

  selectStep() {
    if (this.state.step === -1) {
      return (
        <div>
          <h2 className="subtitle2">Certificar documento digital</h2>
          <div className="panel">
            <p>
              Estás a punto de inmutar documentos digitales usando tecnología
              Blockchain o de Registro Distribuido. Ten en cuenta que los
              documentos no serán almacenados en nuestros sistemas, solo
              calculamos el hash o huella digital del documento y los guardamos
              en las Blockchains disponibles por nuestra solución.
            </p>
            <div className="align-center">
              <Spin indicator={antIcon} tip="Procesando documento..." style={{fontSize: "20px", color: "#801854"}}></Spin>
            </div>
          </div>
        </div>
      );
    }
    if (this.state.step === 0) {
      const { fileList } = this.state;
      const props = {
        multiple: true,
        beforeUpload: (file: any) => {
          let fileBase64: any;
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            fileBase64 = reader.result ? reader.result : undefined;
            //VALIDAMOS SI EXCEDE EL PESO REQUERIDO
            if (Number(file.size) < Number(config.fileSize)) {
              //console.log(file.size);
              //console.log(file.name);
              var foundFile = this.state.fileList.find(function(element: any) {
                return element.name == file.name && element.size == file.size;
              });
              //console.log(this.state.fileList);
              //console.log(foundFile);
              //VALIDAMOS SI EL ARCHIVO YA EXISTE EN LA BANDEJA DE ELEMENTOS POR SUBIR EN BATCH
              if (foundFile !== undefined) {
                toast.error(
                  "EL DOCUMENTO YA EXISTE EN LA BANDEJA DE ARCHIVOS, SELECCIONE OTRO DOCUMENTO",
                  {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                  }
                );
              } else {
                if (fileBase64 !== undefined) {
                  fileBase64 = fileBase64.toString().split(",")[1];
                  const _hash = hash
                    .sha256()
                    .update(fileBase64)
                    .digest("hex");

                  const filebatchitem =
                    localStorage.getItem("keep_doc") === "S"
                      ? { name: file.name, content: fileBase64 }
                      : { name: file.name, document_hash: _hash };

                  this.setState((state: any) => ({
                    ...state,
                    file1: file,
                    hash: _hash,
                    base64: fileBase64,
                    fileList: [...state.fileList, file],
                    filesBatch: [...state.filesBatch, filebatchitem]
                  }));
                  //console.log(this.state.filesBatch);
                }
              }
            } else {
              toast.error(
                "SOLO SE PERMITEN ARCHIVOS QUE PESEN HASTA" +
                  utils.formatBytes(config.fileSize),
                {
                  position: toast.POSITION.TOP_CENTER,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true
                }
              );
            }
          };
          return false;
        },
        fileList
      };
      //console.log(fileList);
      const nrodeitems = fileList.length;
      return (
        <div>
          <h2 className="subtitle2">CERTIFICAR DOCUMENTO DIGITAL</h2>
          <div className="panel">
            <p>
              Estás a punto de inmutar documentos digitales usando tecnología
              Blockchain o de Registro Distribuido. Ten en cuenta que los
              documentos no serán almacenados en nuestros sistemas, solo
              calculamos el hash o huella digital del documento y los guardamos
              en las Blockchains disponibles por nuestra solución.
            </p>
            <p className="txt text-left">
              Por favor, sube el documento que quieres certificar (SOLO ARCHIVOS QUE PESEN
              HASTA {utils.formatBytes(config.fileSize)}):
            </p>
            <div className="contenedor">
              <Dragger {...props} className="dragu">
                <div className="navi">
                  <div className="navi__text">
                    Seleccione documentos <span id="seleccionedesktop">o arrastre y suelte los archivos como
                    desee.</span>
                  </div>
                  <div className="navi__action">
                    <div className="navi__action__left">
                      <span>{nrodeitems}</span>
                      <button className="button plus">+</button>
                    </div>
                  </div>
                </div>
                {fileList.map((file: any, i: any) => (
                  <div key={i} className="filesBatch__item">
                    <div
                      className={
                        "filesBatch__item__type " + utils.fileType(file.type)
                      }
                    >
                      {utils.fileType(file.type)}
                    </div>
                    <Tooltip title={file.name}>
                      <div className="filesBatch__item__name">{file.name}</div>
                    </Tooltip>
                    <div className="filesBatch__item__size">
                      {utils.formatBytes(file.size)}
                    </div>
                    <div className="filesBatch__item__size">{file.sha}</div>
                    <a
                      className="filesBatch__item__close"
                      onClick={(e: any) => this.handleRemoveFile(e, file.uid)}
                    >
                      <span className="filesBatch__item__close__ico">X</span>
                    </a>
                  </div>
                ))}
              </Dragger>

              <div className="navi2">
                <div className="navi2__action">
                  <div className="navi2__action__right">
                    <button
                      className="button"
                      onClick={e => this.onCertificar(e)}
                    >
                      Certificar documento
                    </button>
                  </div>
                </div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (this.state.step === 1) {
      //RESPUESTA EVIDENCIA
      const { filesBatchResult } = this.state;
      console.log(filesBatchResult);
      return (
        <div className="content">
          <h2 className="subtitle2">Evidencia de Certificación</h2>
          <p>
            Documentos certificados con éxito y registro de evidencias en los
            siguientes Blockchains/DLTs:
          </p>
          {filesBatchResult.map((item: any, i: any) => (
            <div key={i} className="itemResul">
              <Row type="flex" justify="space-between" align="middle">
                <Col xs={24} sm={6}>
                  <div className="logo">
                    {item.status === true ? (
                      <div className="msgbatch">
                        <Icon
                          type="check-circle"
                          theme="twoTone"
                          twoToneColor="#52c41a"
                          style={{ fontSize: "42px" }}
                        />
                      </div>
                    ) : (
                      <div className="msgbatch">
                        <Icon
                          type="warning"
                          theme="twoTone"
                          twoToneColor="#F1B434"
                          style={{ fontSize: "42px" }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="logo">
                    {localStorage.getItem("platform") === "I" ? (
                      <img src={logoIOTA} width="140" />
                    ) : (
                      <img src={logoLACCHAIN} width="140" />
                    )}
                  </div>
                </Col>
                <Col xs={24} sm={18}>
                  <div className="datoFile">
                    {item.status === false && (
                      <p className="warning">{item.error_message}</p>
                    )}
                    <p>
                      <strong>Hash (SHA256) del documento</strong>:{" "}
                      {item.document_hash}
                    </p>
                    {localStorage.getItem("platform") === "I" ? (
                      <>
                        {item.status === true && (
                          <p>
                          <strong>Transfer Hash</strong>: {item.transfer_hash}
                          </p>
                        )}
                        <p>
                          <strong>Tipo</strong>: IOTA Tangle (DAG)
                        </p>
                        {item.status === true && (
                          <p>
                            <a href={item.url_explorer} target="_blank">
                              Link al explorador
                            </a>
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        {item.status === true && (
                          <p>
                            <strong>Transfer Hash</strong>: {item.transfer_hash}
                          </p>
                        )}
                        <p>
                          <strong>Tipo</strong>: LACCHAIN - Privado
                        </p>
                        {item.status === true && (
                          <p>
                            <a href={item.url_explorer} target="_blank">
                              Link al explorador
                            </a>
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          ))}

          <Row type="flex" justify="center" align="middle">
            <a className="button" href={`/${URL_ENTERPRISE}/documents`}>
              Regresar a listado de documentos
            </a>
          </Row>
        </div>
      );
    }
  }

  render() {
    return <div className="centerWrap">{this.selectStep()}</div>;
  }
}

export default CertificationComponent;