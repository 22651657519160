import * as React from 'react';
import {
  Form,
  Input,
  Icon
} from 'antd';

class LoginHome extends React.Component<any, any>  {

  constructor(props: any) {
    super(props);
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { onLogin, handleChange, textBoton, buttonEnabled, } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };
    return (
      <div className="login">
        <h2 className="title">LOGIN</h2>
        <Form {...formItemLayout}>
          <Form.Item label="E-mail">
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: 'El campo no es un E-mail válido!',
                },
                {
                  required: true,
                  message: 'Por favor ingresa tu E-mail!',
                },
              ],
            })(<Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} onChange={(e: any) => { handleChange({ email: e.target.value }, 'user'); }} />)}
          </Form.Item>
          <Form.Item label="Password">
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'Por favor ingresa tu password!',
                }
              ],
            })(<Input.Password prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} onChange={(e: any) => { handleChange({ password: e.target.value }, 'user'); }} />)}
          </Form.Item>
          <div className="align-center">
            <button disabled={!buttonEnabled} className="button" onClick={(e: any) => onLogin(e, this.props)}>{textBoton}</button>
            {/*
            <Button type="primary" htmlType="submit" disabled={!buttonEnabled} onClick={(e: any) => onLogin(e, this.props)}>
              {textBoton}
            </Button>
            */}
          </div>
          {/*<div className="text align-center">Si no tienes cuenta, &nbsp;<a href="/register">haz clic aquí</a></div>*/}
        </Form>
      </div>
    );
  }
}

export default Form.create<any>({ name: 'horizontal_login' })(LoginHome);
