import * as React from "react";
import { Form, Input } from "antd";
import QRCode from "qrcode.react";
import ReactInputVerificationCode from 'react-input-verification-code';

class LoginEnabledGoogle extends React.Component<any, any> {
  public constructor(props: any) {
    super(props);
    this.state = {
      isError: false,
      dontInit: false
    }
  }

  addSpaceAfter3Digits = (data:any, type:string) => {
    this.setState({dontInit: true});

    if(data.google_key[0] === "·" && this.state.dontInit) 
      this.setState({isError: true})
    else  
      this.setState({isError: false})

    this.props.handleChange(data, type);
  }

  preValidation = (e:any) => {
    let value = this.props.user.google_key[0];
    let value2 = this.props.user.google_key[5];
    if(value === "·" || value2 === "·") {
      this.setState({isError: true})
      return;
    }

    this.props.onGAFirst(e, this.props)
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { isError } = this.state;
    const {
      onGAFirst,
      handleChange,
      user,
      textBoton,
      buttonEnabled
    } = this.props;

    console.log(user.google_key);
    return (
      <div className="login">
        <h2 className="title">HABILITAR LA GOOGLE AUTHENTICATOR</h2>
        <div className="login-sub-top">
          <div className="num">1</div>
          <div className="subtitle">
            Descarga e instala la aplicación Google Authenticator
          </div>
        </div>
        <div className="login-form-row down-actions margin-left">
          <a href="#" className="btn-down">
            <img
              src={require("../../assets/img/donwload-playstore.svg")}
              alt=""
              width="146"
              height="40"
            />
          </a>
          <a href="#" className="btn-down">
            <img
              src={require("../../assets/img/donwload-appstore.svg")}
              alt=""
              width="146"
              height="40"
            />
          </a>
        </div>
        <Form>
          <div className="login-sub-top">
            <div className="num">2</div>
            <div className="subtitle">Escanea el código QR</div>
          </div>
          <div className="login-form-row margin-left flex">
            <div className="login-form-qr">
              <QRCode value={user.text_qr || "none"} renderAs="svg" />
            </div>
            <div className="login-form-qr-token">
              <p>Account token (key):</p>
              <input
                type="text"
                className="login-form-input token"
                name="token"
                value={user.otp_key}
                readOnly
              />
            </div>
          </div>
          <div className="login-sub-top">
            <div className="num">3</div>
            <div className="subtitle">Habilita la autenticación de Google</div>
          </div>

          <label className="ant-form-item-required" style={{color: 'white'}} title="E-mail">Código de GOOGLE AUTHENTICATOR</label>
            <ReactInputVerificationCode 
              length={6}
              //placeholder={""}
              onChange={(value) => this.addSpaceAfter3Digits({ google_key: value }, "user")}
            />
          {<div className={isError ? 'isError__Messagecustom' : 'Message__Custom'}>Por favor ingresa código de GOOGLE AUTHENTICATOR</div>}

          <div className="align-center">
            <button
              disabled={!buttonEnabled || isError}
              className="button"
              onClick={(e: any) => this.preValidation(e)}
            >
              {textBoton}
            </button>
          </div>
        </Form>
      </div>
    );
  }
}

export default Form.create<any>({ name: "vertical_login" })(LoginEnabledGoogle);
