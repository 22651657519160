import * as React from "react";
import "./detailDocument.css";
import logoIOTA from "../../assets/img/Iota_logo.png";
import logoLACCHAIN from "../../assets/img/logo_LACCHAIN.png";
import { URL_ENTERPRISE } from './../../constant'; 
//import qr from '../../assets/img/qr.png';
import { Row, Col } from "antd";
import * as api from "../../apiWrapper";
import "antd/dist/antd.css";
import QRCode from "qrcode";

class DetailDocumentComponent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      uuid: "",
      name: "",
      hash: ""
    };
  }

  componentDidMount() {
    const hash = this.props.match.params.hash;
    const token = localStorage.getItem("token") + "";
    api
      .documentDetail(token, hash)
      .then((res: any) => {
        const data = res.data.document;
        console.log(data);
        this.setState({
          uuid: data.uuid,
          name: data.name,
          hash: data.document_hash,
          tx_hash: data.transaction_hash,
          url_explorer: data.url_explorer
        });
        const qrcode_doc = this.refs.qrcode_doc;
        const url_doc =
          "https://magictrust.magia.digital/verification/" + data.document_hash; // el id deber ser del documento !!!
        QRCode.toCanvas(qrcode_doc, url_doc);
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { uuid, name, hash, tx_hash, url_explorer } = this.state;
    return (
      <div className="content detalleDocumento">
        <h2 className="subtitle2">Evidencia de Certificación</h2>
        <Row type="flex" justify="space-between" align="middle">
          <Col xs={24} sm={8}>
            <div className="logo">
              <canvas ref="qrcode_doc"></canvas>
            </div>
          </Col>
          <Col xs={24} sm={16}>
            <div className="datoFile">
              <p>
                <strong>Magic Chain Trust ID</strong>: {uuid}
              </p>
              <p>
                <strong>Nombre del documento digital certificado</strong>: {name}
              </p>
              <p>
                <strong>Hash (SHA256) del documento</strong>: {hash}
              </p>
            </div>
          </Col>
        </Row>

        <p>
          Documentos certificados con éxito y registro de evidencias en los
          siguientes Blockchains/DLTs:
        </p>

        <Row
          type="flex"
          justify="space-between"
          align="middle"
          className="margin-bottom"
        >
          <Col xs={24} sm={8}>
            <div className="logo">
              {localStorage.getItem("platform") === "I" ? (
                <img src={logoIOTA} width="140" />
              ) : (
                <img src={logoLACCHAIN} width="140" />
              )}
            </div>
          </Col>
          <Col xs={24} sm={16}>
            <div className="datoFile">
              {localStorage.getItem("platform") === "I" ? (
                <>
                  <p>
                    <strong>Transfer Hash</strong>: {tx_hash}
                  </p>
                  <p>
                    <strong>Tipo</strong>: IOTA Tangle (DAG)
                  </p>
                  <p>
                    <a href={url_explorer} target="_blank">
                      Link al explorador
                    </a>
                  </p>
                </>
              ) : (
                <>
                  <p>
                    <strong>Transfer Hash</strong>: {tx_hash}
                  </p>
                  <p>
                    <strong>Tipo</strong>: Quorum (Fork Ehereum) en TestNet -
                    Privado
                  </p>
                  <p>
                    <a href={url_explorer} target="_blank">
                      Link al explorador
                    </a>
                  </p>
                </>
              )}
            </div>
          </Col>
        </Row>

        <Row type="flex" justify="center" align="middle">
          <a className="button" href={`/${URL_ENTERPRISE}/documents`}>
            Regresar a listado de documentos
          </a>
        </Row>
      </div>
    );
  }
}

export default DetailDocumentComponent;
