import * as React from "react";
import upload from "../../assets/img/upload.svg";
import { Form, Icon, Input, Button, Checkbox } from "antd";
import { URL_ENTERPRISE } from './../../constant'; 
import "./verificationFailed.css";

import logoIOTA from "../../assets/img/Iota_logo.png";
import logoIDBLAB from "../../assets/img/logo_IDB-LAB.png";
import logoLACCHAIN from "../../assets/img/logo_LACCHAIN.png";
import ok from "../../assets/img/ico-ok.png";
import fail from "../../assets/img/ico-fail.png";
import qr from "../../assets/img/qr.png";
import { Row, Col } from "antd";
import "antd/dist/antd.css";

import axios from "axios";

let inputElement: HTMLInputElement | null;

export class VerificationFailedComponent extends React.Component<any, any> {
  private url: string;

  constructor(props: any) {
    super(props);
    this.state = {
      step: 0,
      file1: "",
      sol: "",
      descr: ""
    };
    this.url = "https://api-certs.magiadigital.com/api/";
  }

  render() {
    const { sol, descr } = this.state.sol;
    return (
      <div className="centerWrap">
        <h2 className="subtitle2">VALIDAR DOCUMENTO DIGITAL</h2>

        <Row
          type="flex"
          justify="center"
          align="middle"
          className="margin-bottom"
        >
          <p className="rpta">
            <img src={fail} width="98" />
          </p>
        </Row>

        <p className="text-center margin-bottom">
          Este documento NO HA SIDO validado en la Blockchain con Magic Chain
          TRUST
          <br /> o es un documento adulterado.
        </p>

        <Row type="flex" justify="center" align="middle">
          <a className="button" href={`/${URL_ENTERPRISE}/verification`}>
            Validar otro documento
          </a>
        </Row>
      </div>
    );
  }
}

export default VerificationFailedComponent;
