import * as React from 'react';
import * as api from "../../apiWrapper";
import { Switch, Route, Redirect } from 'react-router-dom';
import HomeComponent from '../home/home';
import LoginComponent from '../login/login';
import RegisterComponent from '../register/register';
import CertificationComponent from '../certification/certification';
import VerificationComponent from '../verification/verification';
import ListDocumentsComponent from '../listDocuments/listDocuments';
import DetailDocumentComponent from '../detailDocument/detailDocument';
import VerificationOKComponent from '../verificationOK/verificationOK';
import VerificationFailedComponent from '../verificationFailed/verificationFailed';
import './main.css';
import { PrivateRoute } from '../../privateRoute';
import { URL_ENTERPRISE, API_TOKEN_LISTADO } from './../../constant'; 

const NoMatchPage = () => {
  return (
    <h3>La página/empresa que buscas no existe</h3>
  );
};

const NoMatchPageEnterprise = () => {

  return (
    <h3>La página que buscas no existe, ir a <a href={`/${URL_ENTERPRISE}`}>Home</a></h3>
  );
};

export class MainComponent extends React.Component<any, any> {
  constructor(props:any){
    super(props);
    this.state = {
      data: []
    }
  }

  componentDidMount(){
    if(!localStorage.getItem("customPortal")){
      this.onInitAPI();
      console.log("NO HAY DATA")
    } else {
      this.onInitLocalStorage();
      console.log("SI HAY DATA")
    }

  }

  onInitAPI = () => {
    api.clientList(API_TOKEN_LISTADO)
    .then((res:any) => {
      const { data: { data } } = res;
      localStorage.setItem("customPortal", JSON.stringify(data));
      this.setState({data});
    }) 
    .catch((err) => {
      console.log(err);
    })
  }

  onInitLocalStorage = () => {
    let items = JSON.parse(localStorage.getItem("customPortal") || "[]");
    this.setState({data: items});
  }

  render() {
    const urlPortals:Array<string> = this.state.data.map((item:any) => {
      if(item.route_portal !== null) return item.route_portal;
    });
    const home:Array<string> = [],
          login:Array<string> = [], 
          register:Array<string> = [], 
          certification:Array<string> = [],
          verification:Array<string> = [],
          documents:Array<string> = [],
          detailDocument:Array<string> = [],
          verificationOK:Array<string> = [],
          verificationFailed:Array<string> = [],
          pageNotFoundEnterprise:Array<string> = [];

    urlPortals.map((item) => {
      home.push(`/${item}`);
      login.push(`/${item}/login`);
      register.push(`/${item}/register`);
      certification.push(`/${item}/certification`);
      verification.push(`/${item}/verification/:hash?`);
      documents.push(`/${item}/documents`);
      detailDocument.push(`/${item}/detail-document/:hash`);
      verificationOK.push(`/${item}/verificationOK/:hash`);
      verificationFailed.push(`/${item}/verificationFailed`);
      pageNotFoundEnterprise.push(`/${item}/*`)
    });
    //console.log(urlPortals);
    //console.log(login, register, certification, verification, documents, detailDocument, verificationOK, verificationFailed);

    return (
      <main className="main-component" >

        <Switch>
          <Route exact path={home} component={HomeComponent} />
          <Route exact path={login} component={LoginComponent} />
          <Route exact path={register} component={RegisterComponent} />
          <PrivateRoute exact path={certification} component={CertificationComponent} />
          <Route exact path={verification} component={VerificationComponent} />
          <PrivateRoute exact path={documents} component={ListDocumentsComponent} />
          <PrivateRoute exact path={detailDocument} component={DetailDocumentComponent} />
          <Route exact path={verificationOK} component={VerificationOKComponent} />
          <Route exact path={verificationFailed} component={VerificationFailedComponent} />
          <Route exact path={pageNotFoundEnterprise} component={NoMatchPageEnterprise} />
          <Route component={NoMatchPage} />
        </Switch>

      </main>
    )
  }

}