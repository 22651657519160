const fileType = (tipo: any) => {
    var tipodetectado;
    switch (tipo) {
      case "image/jpeg":
        tipodetectado = "JPG";
        break;
      case "image/png":
        tipodetectado = "PNG";
        break;        
      case "application/msword":
        tipodetectado = "W";
        break;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        tipodetectado = "W";
        break;        
      case "application/vnd.ms-excel":
        tipodetectado = "X";
        break;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        tipodetectado = "X";
        break;        
      case "application/pdf":
        tipodetectado = "PDF";
        break;
      case "application/vnd.ms-powerpoint":
        tipodetectado = "PPT";
        break;
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        tipodetectado = "PPT";
        break;
      default:
        tipodetectado = "genérico";
    }
    return tipodetectado;
}

const formatBytes = (bytes: any, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export { fileType, formatBytes };