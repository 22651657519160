import * as React from 'react';
import './home.css';
import imgUpload from './../../assets/img/icono_subir.svg';
import imgSearch from './../../assets/img/icono_lupa.svg';

import imgDiagrama from './../../assets/img/diagramaH.png';
import logoLogistica from './../../assets/img/Logistica.png';
import logoCalidad from './../../assets/img/Calidad.png';
import logoAuditoria from './../../assets/img/List.png';
import logoDataMarkets from './../../assets/img/DataMarkets.png';
import logoMarketing from './../../assets/img/Marketing.png';
import { URL_ENTERPRISE, ITEM_ENTERPRISE, URL_BASE_IMAGE } from './../../constant';
import { Row, Col } from 'antd';
import "antd/dist/antd.css";

class HomeComponent extends React.Component<any, any>  {

  constructor(props: any) {
    super(props);
  }

  render() {

    return (
      <>
        { ITEM_ENTERPRISE.banner_path && 
          <div className="banner__home">
            <img src={ URL_BASE_IMAGE + ITEM_ENTERPRISE.banner_path} alt="Imagen Banner" />
          </div>
        }
        <div className="content home">

          <div className="block" dangerouslySetInnerHTML={{__html: ITEM_ENTERPRISE.description_page}}>
            
            {/*Estás a un paso de certificar la autenticidad del contenido original y fecha cierta de existencia de cualquier tipo de documento digital o información, a través del uso de las tecnologías Blockchain o de Registro Distribuido (DLT).<br /><br />No necesitas saber programar ni mucho menos, con nosotros lo podrás hacer a solo unos clics de distancia.*/}
          </div>
          <div className="subtitle2" style={{ color: 'black' }}> <strong>¿QUÉ QUIERES HACER HOY?</strong> </div>

          <Row type="flex" justify="space-between" align="middle" className="margin-bottom">
            <Col xs={24} sm={10} className="margin-bottom-mobile" style={{ textAlignLast: 'center' }}>
              <img className="change-color-img" src={imgUpload} height="150" width='100%' />
              <a href={`/${URL_ENTERPRISE}/documents`} className="button  _btn_primary full large" >SUBIR DOCUMENTOS</a>
            </Col>
            <Col xs={24} sm={10} style={{ textAlignLast: 'center' }}>
              <img className="change-color-img" src={imgSearch} height="150" width='100%' />
              <a href={`/${URL_ENTERPRISE}/verification`} className="button _btn_primary full large" >VERIFICAR DOCUMENTOS</a>
            </Col>
          </Row>

          <div className="linear-between"/>

          <div className="subtitle2" style={{ color: '#2c2a29', textAlign: 'justify' }}><strong>TRAZABILIDAD BLOCKCHAIN</strong></div>
          <div className="block">
            <strong>El siguiente paso</strong>
            <br />
            <br />
            A través de Magic Trust has podido anclar en un Blockchain documentos digitales y así conservarlo íntegro e inmutable...
            pero esto no queda aquí. Gracias a la tecnología Blockchain podremos también registrar datos de cada proceso es una cadena
            de valor. Esto es conocido como TRAZABILIDAD.
            <br />
            <br />
          </div>
          <div className="subtitle2" style={{ color: '#2c2a29' }}><strong>REGISTRA TODA LA CADENA DE VALOR</strong></div>

          <Row type="flex" justify="space-between" align="middle" className="margin-bottom" style={{ justifyContent: 'space-evenly' }}>
            <Col xs={24} sm={20} className="margin-bottom-mobile">
              <img src={imgDiagrama} style={{ paddingBottom: '20px', width: '100%' }} />
            </Col>
          </Row>

          <div className="subtitle2" style={{ color: '#2c2a29' }}><strong>BENEFICIOS</strong></div>
          <Row type="flex" justify="space-between" align="middle" className="margin-bottom">
            <Col xs={24} sm={24} className="margin-bottom-mobile">
              <div className="List_beneficios">
                {
                  ListBeneficios.map((item: any, index: any) => <div className="beneficio" key={index}>
                    <img src={item.logo} alt='not found' height='35' />
                    <br />
                    <br />
                    <p>{item.title}</p>
                    <p style={{ fontSize: '15px'}}>{item.description}</p>
                  </div>)
                }
              </div>
            </Col>
          </Row>
          {/*<Row type="flex" justify="space-between" align="middle" className="margin-bottom">
          <Col span={12}>
            <div className="logo">
              <img src={logoLACCHAIN} height="100" />           
            </div>
          </Col>
          <Col span={12}>
            <div className="logo align-right">
              <img src={logoIOTA} height="100" />              
            </div>
          </Col>          
        </Row>*/}



        </div>
      </>
    )
  }
}

const ListBeneficios = [
  {
    logo: logoLogistica,
    title: 'LOGÍSTICA',
    description: 'Control del Supply Chain'
  },
  {
    logo: logoCalidad,
    title: 'CALIDAD',
    description: 'Conocer el origen de fallas, analizarlas, evitarlas'
  },
  {
    logo: logoAuditoria,
    title: 'AUDITORIA',
    description: 'Financiera (de documentos) y Operativa (de mediciones)'
  },
  {
    logo: logoDataMarkets,
    title: 'DATA MARKETS',
    description: 'Compra y venta de data Streams, menos intermediarios'
  },
  {
    logo: logoMarketing,
    title: 'MARKETING',
    description: 'Demostrar procedencia del producto. Responsabilidad Social Corporativa'
  },
]

export default (HomeComponent);
