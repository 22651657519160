const pathname = window.location.pathname;
const index = (pathname.indexOf("/", 2)) - 1;
export const URL_ENTERPRISE = pathname.substr(1, index === -2 ? pathname.length : index );

declare var window: any;
export var API_TOKEN_LISTADO = window.config.tokenListado;

//===================================================================

interface IItem_Enterprise {
    banner_path: string,
    content_page: string,
    creation_date: string,
    description_page: string,
    email: string,
    id: number,
    logo_path: string,
    name: string,
    route_portal: string,
    status: string,
    theme_config: string,
    update_date: string
}

let items = JSON.parse(localStorage.getItem("customPortal") || "[]");

const searchPath = (items:any) => { 
    let temp:any = {};
    items.map((item:IItem_Enterprise) => {
        if(item.route_portal === URL_ENTERPRISE) {
            temp = item;
        }
    })
    return temp;
}

export const ITEM_ENTERPRISE:IItem_Enterprise = searchPath(items);
export const URL_BASE_IMAGE:string = "http://admin.magicchain-trust.magiadigital.com/media/";